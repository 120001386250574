@tailwind base;
@tailwind components;
@tailwind utilities;

/* crisp */
[data-is-failure="false"].cc-nsge {
  transform: scale(0.75);
  translate: 1.5rem .75rem;
  transform-origin: bottom;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}


@layer base {
  .scroll {
    @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-neutral-600/30 scrollbar-thumb-rounded-lg
  }
}


@layer base {
  * {
    --radius: 0.5rem;
    --tw-exit-opacity: 0.7;
    @apply border-neutral-200
  }
  body {
    font-feature-settings: "rlig" 1, "calt" 1;
  }
  a {
    @apply underline-offset-2 hover:underline
  }
}
